import { useState } from 'react'
import styles from '@/components/home/v2/ProductSection.module.css'
import { motion } from 'framer-motion'

const parentVariants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
}

const childVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.5 },
  },
}

const ProductSection = () => {
  const products = {
    consumers: [
      { id: '1', title: 'EV Health Checker App', description: 'Test the battery health of any EV with our app.' },
      { id: '2', title: 'Used EV Lookup', description: 'Find detailed information about used EVs.' },
      { id: '3', title: 'EV Explorer', description: 'Explore EVs that match your preferences.' },
      { id: '4', title: 'EV Knowledge Hub', description: 'Learn about electric vehicles.' },
    ],
    businesses: [
      { id: '1', title: 'Test 1', description: 'Generate EV resale reports to boost sales.' },
      { id: '2', title: 'Flash Battery Health Checks', description: 'Quick battery checks for EVs.' },
      { id: '3', title: 'Independent Battery Health Checks', description: 'Accurate and unbiased checks.' },
      { id: '4', title: 'EV API Data', description: 'Integrate EV data into your platform.' },
    ],
  }

  const [activeSection, setActiveSection] = useState('consumers') // "consumers" or "businesses"
  const [activeProductId, setActiveProductId] = useState(products.consumers[0].id) // Default to first consumer product

  const handleSectionToggle = (section) => {
    if (section !== activeSection) {
      setActiveSection(section)
      setActiveProductId(products[section][0].id) // Set the first product of the new section
    }
  }

  const handleProductClick = (productId) => {
    if (productId !== activeProductId) {
      console.log('Product clicked:', productId)
      setActiveProductId(productId)
    }
  }

  const currentProducts = products[activeSection] // Get the products for the active section

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.sectionIntroduction}>
          <h3 className={styles.h3}>Our Products</h3>
        </div>
        <div className={styles.sectionContent}>
          {/* Product Selector */}
          <div className={styles.productSelector}>
            <div
              className={`${styles.tabContainer} ${
                activeSection === 'consumers' ? styles.activeTabContainer : styles.inactiveTabContainer
              }`}
              onClick={() => handleSectionToggle('consumers')}
            >
              <div className={styles.tabHeader}>For Consumers</div>
              {activeSection === 'consumers' && (
                <div className={styles.productList}>
                  {currentProducts.map((product) => (
                    <div
                      key={product.id}
                      className={product.id === activeProductId ? styles.productItemActive : styles.productItemInactive}
                      onClick={() => handleProductClick(product.id)}
                    >
                      <div className={styles.productTitle}>{product.title}</div>
                      {product.id === activeProductId && (
                        <div className={styles.productDescription}>{product.description}</div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div
              className={`${styles.tabContainer} ${
                activeSection === 'businesses' ? styles.activeTabContainer : styles.inactiveTabContainer
              }`}
              onClick={() => handleSectionToggle('businesses')}
            >
              <div className={styles.tabHeader}>For Businesses</div>
              {activeSection === 'businesses' && (
                <div className={styles.productList}>
                  {currentProducts.map((product) => (
                    <div
                      key={product.id}
                      className={product.id === activeProductId ? styles.productItemActive : styles.productItemInactive}
                      onClick={() => handleProductClick(product.id)}
                    >
                      <div className={styles.productTitle}>{product.title}</div>
                      {product.id === activeProductId && (
                        <div className={styles.productDescription}>{product.description}</div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Product Preview */}
          <div className={styles.productPreview}>
            {activeProductId ? (
              <div className={styles.productDetails}>
                <h4>{currentProducts.find((product) => product.id === activeProductId)?.title}</h4>
                <p>{currentProducts.find((product) => product.id === activeProductId)?.description}</p>
              </div>
            ) : (
              <div className={styles.placeholder}>Select a product to view details</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductSection
